// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/right-arrow.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".content[data-v-4e12d770] {\n  background-color: #fafafa;\n  display: flex;\n  height: 100%;\n  flex-direction: column;\n  padding: 0.625rem 0.75rem;\n}\n.content .list[data-v-4e12d770] {\n  display: flex;\n  flex-direction: column;\n  background: #FFFFFF;\n  box-shadow: 0 0.25rem 1.0625rem -0.25rem rgba(16, 31, 46, 0.04);\n  border-radius: 0.25rem;\n}\n.content .list .item[data-v-4e12d770] {\n  font-size: 0.875rem;\n  font-family: PingFangSC-Regular, PingFang SC;\n  font-weight: 400;\n  color: #262626;\n  line-height: 0.875rem;\n  padding: 1.0625rem 0.875rem;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.content .list .item i[data-v-4e12d770] {\n  display: block;\n  width: 0.5rem;\n  height: 0.8125rem;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n  background-size: auto 100%;\n}\n.content .list .item[data-v-4e12d770]:first-child {\n  border-bottom: 0.0625rem solid #f3f3f3;\n}\n", ""]);
// Exports
module.exports = exports;

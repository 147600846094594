<template>
	<div class="content">
		<div class="list">
			<div class="item" @click="clickToServiceProtocol">
				<span>用户服务协议</span>
				<i />
			</div>
			<div class="item" @click="clickToPrivatePolicy">
				<span>隐私政策</span>
				<i />
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data () {
		return {
			name: ''
		};
	},
	created () {
	},
	methods: {
		clickToPrivatePolicy () {
			this.$root.go('/policy', { id: 'user-privacy' });
		},
		clickToServiceProtocol () {
			this.$root.go('/policy', { id: 'user-service' });
		}
	}
};
</script>
<style lang="less" scoped>
	.content {
		background-color: #fafafa;
		display: flex;
		height: 100%;
		flex-direction: column;
		padding: 10px 12px;
		.list {
			display: flex;
			flex-direction: column;
			background: #FFFFFF;
			box-shadow: 0px 4px 17px -4px rgba(16,31,46,0.04);
			border-radius: 4px;
			.item {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #262626;
				line-height: 14px;
				padding: 17px 14px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				i {
					display: block;
					width: 8px;
					height: 13px;
					background: url(../../assets/images/right-arrow.png) center center no-repeat;
					background-size: auto 100%;
				}
			}
			.item:first-child {
				border-bottom: 1px solid #f3f3f3;
			}
		}
	}
</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "list" }, [
      _c(
        "div",
        { staticClass: "item", on: { click: _vm.clickToServiceProtocol } },
        [_c("span", [_vm._v("用户服务协议")]), _c("i")]
      ),
      _c(
        "div",
        { staticClass: "item", on: { click: _vm.clickToPrivatePolicy } },
        [_c("span", [_vm._v("隐私政策")]), _c("i")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }